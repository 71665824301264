import BaseModal, { ModalProps } from '@ui-v2/core/Modal/Modal';
import useGetIcon from '@web/context/hooks/useGetIcon';

export type Props = Omit<ModalProps, 'closeIcon'> & { hideCloseIcon?: boolean };

const Modal = ({ hideCloseIcon, ...props }: Props) => {
  const closeIcon = useGetIcon()('closeIcon');

  return <BaseModal {...props} closeIcon={hideCloseIcon ? null : closeIcon} />;
};

export default Modal;
